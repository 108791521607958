import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/banner.css"
import "../styles/contact-btn.css"
import "../styles/section.css"
import "../styles/nosotros.css"

import flecha_blanca from "../images/arrow-down-white.svg"
import bgBanner from "../images/Nosotros/bgImage.jpg"
import hermanos from "../images/Nosotros/hermanos.jpg"

const NosotrosPage = () => (
  <Layout>
    <SEO
      title="Nostros"
      description="Especialistas en asesoría, análisis e implementación de soluciones fiscales para personas físicas y morales."
      url="https://www.veleztax.com/nosotros"
      image={bgBanner}
    />
    <section className="banner nosotros_banner">
      <h1>Nosotros</h1>
      <p>
        Especialistas en asesoría, análisis e implementación de soluciones
        fiscales para personas físicas y morales.
      </p>

      <div
        className="banner__picture"
        style={{ backgroundImage: "url(" + bgBanner + ")" }}
      />
    </section>
    <section className="section nosotros_section">
      <div className="section_2 nosotros__section_2">
        <div className="section_title">
          <div className="preTitle">
            <div className="vector" />
            <span>Servicios</span>
          </div>
          <h2>¿Qué ofrecemos?</h2>
        </div>
        <p style={{ maxWidth: "100%" }} className="nosotros_offer_text">
          Brindamos asesoría fiscal y legal a compañías nacionales y
          multinacionales que buscan proyectos de fusión y adquisiciones (M&A),
          reestructuras corporativas y estructuras de financiamiento. Somos
          expertos en fondos de capital privado con inversiones en México sobre
          todo en los sectores de construcción, salud, infraestructura,
          educación, transporte y logística.
        </p>
        <Link className="contact-btn nosotros_contact-btn" to="/contacto">
          Conoce Más
          <img src={flecha_blanca} alt="flecha" />
        </Link>
      </div>
    </section>
    <div
      className="nosotros__cover_image"
      style={{ backgroundImage: "url(" + hermanos + ")" }}
    />
  </Layout>
)

export default NosotrosPage
